import translation from '@tag/i18n/src/languages/en.json';

/**
 * Add Notification
 *
 * @param payload Notification object
 */
export class AddNotification {
  static readonly type = '[Notification] Add';

  constructor(
    public body: string,
    public type: 'info' | 'error' | 'success' | 'warning',
    public title?: string
  ) {}
}

/**
 * Add Notification and translate
 *
 * @param payload Notification object
 */
export class AddI18nNotification {
  static readonly type = '[Notification] AddI18n';

  constructor(
    public body: { key: string; params?: Record<string, any> },
    public type: 'info' | 'error' | 'success' | 'warning',
    public title?: keyof typeof translation
  ) {}
}

/**
 * Delete Notification, this method will dynamically update the cache Notification list and set selected Notification to null.
 *
 * @param body Notification No
 */
export class DeleteNotification {
  static readonly type = '[Notification] Delete';

  constructor(public timestamp: number) {}
}

/**
 * Delete All Notification.
 */
export class DeleteAllNotification {
  static readonly type = '[Notification] Delete All';
}

/**
 * Set selected Notification
 *
 * @param payload New Notification full object.
 */
export class SetSelectedNotification {
  static readonly type = '[Notification] Set';

  constructor(public payload: Notification | null) {}
}
